import React from "react";
import LoginContainer from "../components/pageComponents/Login/LoginContainer";
import styles from "../styles/Login.module.css";
import { Helmet } from "react-helmet-async";

const Login = () => {
    return(
        
        <div className={styles.mainContainer}>
            <Helmet>
                <title>{'The Morning View | Σύνδεση - Διαχειριστικό'}</title>
            </Helmet>
            <LoginContainer />
            
        </div>
    )
}

export default Login;
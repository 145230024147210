import clock from '../../../images/datedark.png';
import trash from '../../../images/trash_red.png';
import edit from '../../../images/edit_red.png';
// import { Link } from 'react-router-dom';
import styles from '../../../styles/Articles.module.css'
import axios from 'axios';
import { BaseUrl } from '../../../App';


const articleRestore = (id) => {
    axios.post(BaseUrl+'/restore-article',{id: id}).then((response)=>{
        if(response.data.success === true){
            window.location.reload();
        }
    })
}
const articlePurge = (id) => {
    axios.post(BaseUrl+'/permantly-delete-article',{id: id}).then((response)=>{
        if(response.data.success === true){
            window.location.reload();
        }
    })
}

const SingleTrash = (props) => {
 return (
        
    <div className={`${styles.articlesContainerInner}`}>
            <div className={styles.articlesDivImg}>
            {/* <Link to={'/editArticle'} state={{id: props.articleId}}> */}
                <div className={styles.articlesMainImg} style={{backgroundImage: "url('"+props.imagePath+"')"}}></div>
            {/* </Link> */}
            </div>
            <div className={styles.articlesDetailsDiv}>
            
                <div className={styles.articlesTitle}>
                    {props.title}
                </div>
                <div className={styles.articlesBody} dangerouslySetInnerHTML={{__html: props.body.substring(0,200)}}>
                </div>
                <div className={styles.articlesDate}>
                    <img src={clock}></img>
                    <div>{props.date}</div>
                </div>
                <div className={styles.bottomContainer}>
                    <div className={styles.articlesAuthor}>
                        {props.author}
                    </div>
                    <div className={styles.editdelete}>
                        <div className={styles.editbtn} onClick={() =>{articleRestore(props.articleId)}}><img src={edit}></img><p>Επαναφορά</p></div>
                        {/* <div className={styles.deletebtn} onClick={() =>{articlePurge(props.articleId)}}><img src={trash}></img><p>Διαγραφή</p></div> */}
                    </div>
                </div>
            </div>
    </div>
    

);
}
export default SingleTrash;
import React, { Fragment, useEffect, useRef, useState } from 'react';
import 'devextreme/dist/css/dx.light.css';
import { HtmlEditor, Toolbar, Item, MediaResizing } from 'devextreme-react/html-editor';
import { CheckBox } from 'devextreme-react/check-box';
import { Calendar } from 'devextreme-react/calendar';
import styles from '../../styles/Editor.module.css';
import axios from 'axios';
import { BaseUrl } from '../../App';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Helmet } from 'react-helmet-async';

const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = ["Arial", "Georgia", "Tahoma", "Times New Roman", "Verdana"];
const headerValues = [false, 1, 2, 3, 4, 5];

const Editor = (props) => {
    const pathname = window.location.pathname;
    const [isTitle, setisTitle] = useState(props.material.title);
    const urlKey = useRef();
    const oldUrlKey = useRef();
    const title = useRef();
    const [date, setDate] = useState(new Date());
    const [tags, setTags] = useState([]);
    const [tag, setTag] = useState(null);
    const [validationErrors, setValidationErrors] = useState([]);
    const [firstPageFirstNews, setFirstPageFirstNews] = useState(props.material.first_page_first_news || false);
    const [firstPageFirstNews2, setFirstPageFirstNews2] = useState(props.material.first_page_first_news2 || false);
    const [firstPageFirstNews3, setFirstPageFirstNews3] = useState(props.material.first_page_first_news3 || false);
    const [bottomArticle, setBottomArticle] = useState(props.material.bottom_article || false);
    const [bottomArticle2, setBottomArticle2] = useState(props.material.bottom_article2 || false);
    const [bottomArticle3, setBottomArticle3] = useState(props.material.bottom_article3 || false);
    const [bottomArticle4, setBottomArticle4] = useState(props.material.bottom_article4 || false);
    const [image, setImage] = useState(null);
    const [mainImage, setMainImage] = useState(null);
    const [markup, setMarkup] = useState('');
    const markupRef = useRef(''); // Ref to store the current markup value
    const preview = useRef(props.material?.preview || '');
    const [borderRequired, setborderRequired] = useState(styles.borderRequired);
    const [titleError, settitleError] = useState(styles.titleError);
    const [bodyError, setbodyError] = useState(styles.titleError);
    const [tagError, settagError] = useState(styles.titleError);
    const [htmleditorreq, sethtmleditorreq] = useState({ borderColor: 'lightgrey' });

    const editorRef = useRef(null);
    const cursorPositionRef = useRef(0); // Ref to store the last cursor position
    const fileInputRef = useRef(null); // Ref for the file input

    const handleChange = (e) => {
        settitleError(styles.titleError);
        setborderRequired(styles.borderRequired);
        sethtmleditorreq({ borderColor: 'lightgrey' });
    }

    const handleMarkup = (e) => {
        markupRef.current = e;
        setMarkup(e);
        sethtmleditorreq({ borderColor: 'lightgrey' });
        setbodyError(styles.titleError);
    }

    const handleFileUpload = async (e) => {
        const uploadedFile = e.target.files[0];
        setImage(uploadedFile);
        e.target.value = null; // Clear the input value to allow re-selecting the same file
        if (uploadedFile) {
            const formData = new FormData();
            formData.append('image', uploadedFile);

            try {
                const response = await axios.post(BaseUrl + '/upload-custom-article-image', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                    }
                });

                if (response.data?.success) {
                    const imageUrl = response.data?.data?.imageSrc;
                    const editorInstance = editorRef.current.instance;
                    editorInstance.focus(); // Ensure the editor is focused

                    setTimeout(() => {
                        const currentSelection = editorInstance.getSelection();
                        if (currentSelection) {
                            editorInstance.insertEmbed(currentSelection.index, 'image', imageUrl, 'user');
                            const images = document.querySelectorAll('img[src="' + imageUrl + '"]');
                            images.forEach(img => {
                                img.style.width = '100%';
                                img.style.height = 'auto';
                                img.style.resize = 'both';
                                img.style.overflow = 'auto';
                            });
                            editorInstance.setSelection(currentSelection.index + 1);
                        }
                    }, 0); // Delay to ensure focus and selection are available
                } else {
                    alert(response.data?.message);
                    setImage(null);
                }
            } catch (error) {
                console.error("There was an error uploading the image!", error);
                setImage(null);
            }
        }
    }

    const createUpdateArticle = (id, title, body, date, tag, image, firstPageFirstNews, firstPageFirstNews2, firstPageFirstNews3, bottomArticle, bottomArticle2, bottomArticle3, bottomArticle4, preview, urlKey, oldUrlKey) => {
        setValidationErrors([]);
        if (title.current.value === "") {
            setborderRequired(styles.borderRequiredTrue);
            settitleError(styles.titleErrorTrue);
            window.scroll(0, 0);
        }
        if (markupRef.current === "") {
            sethtmleditorreq({ borderColor: 'red' });
            setbodyError(styles.titleErrorTrue);
        }
        if (tag === null) {
            settagError(styles.titleErrorTrue);
        } else {
            date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
            const formData = new FormData();
            formData.append('id', id);
            formData.append('releaseDate', date);
            formData.append('title', title.current.value);
            formData.append('tagId', tag);
            formData.append('body', markupRef.current);
            if (mainImage) {
                formData.append('image', mainImage);
            }
            formData.append('first_page_first_news', firstPageFirstNews ? 1 : 0);
            formData.append('first_page_first_news2', firstPageFirstNews2 ? 1 : 0);
            formData.append('first_page_first_news3', firstPageFirstNews3 ? 1 : 0);
            formData.append('bottom_article', bottomArticle ? 1 : 0);
            formData.append('bottom_article2', bottomArticle2 ? 1 : 0);
            formData.append('bottom_article3', bottomArticle3 ? 1 : 0);
            formData.append('bottom_article4', bottomArticle4 ? 1 : 0);
            formData.append('url_key', urlKey);
            formData.append('old_url_key', oldUrlKey);
            formData.append('preview', preview.current.value);

            axios.post(BaseUrl + '/create-update-article', formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }).then((response) => {
                if (response.data.success === true) {
                    window.location.replace('/articles');
                }
            }).catch((err) => {
                if (err.response?.data?.success === false) {
                    if (err.response?.data?.data) {
                        let tmpErrors = [];
                        for (let key in err.response.data.data) {
                            tmpErrors.push(err.response.data.data[key]);
                        }
                        setValidationErrors(tmpErrors);
                    }
                }
            });
        }
    }

    const setAllowScripts = () => {
        const iframe = document.querySelector('.dx-htmleditor-content iframe');
        if (iframe) {
            iframe.sandbox += ' allow-scripts';
        }
    }

    useEffect(() => {
        if (pathname === "/editArticle") {
            axios.get(BaseUrl + "/get-tags").then((response) => {
                setTags(response.data.data);
            });
            setMarkup(props.material.body);
            markupRef.current = props.material.body;
            var artDate = new Date(props.material.releaseDate);
            setDate(artDate);
            setTag(props.material.tagId);
        } else {
            axios.get(BaseUrl + "/get-tags").then((response) => {
                setTags(response.data.data);
            });
            setMarkup('');
            markupRef.current = '';
            setDate(new Date());
        }
        setAllowScripts(); // Set allow-scripts after rendering the editor
    }, [pathname, props.material]);

    const handleSelectionChange = (e) => {
        cursorPositionRef.current = e;
    };

    return (
        <div style={{ marginBottom: '30px' }}>
            <Helmet>
                <title>{pathname === "/editArticle" ? 'The Morning View | Επεξεργασία άρθρου - Διαχειριστικό' : 'The Morning View | Προσθήκη άρθρου - Διαχειριστικό'}</title>
            </Helmet>
            <div>
                <label htmlFor="article_title">Τίτλος</label>
                <input id="article_title" className={`${styles.addTitleInner} ${borderRequired}`} type="text" value={isTitle} onChange={e => { setisTitle(e.target.value); settitleError(styles.titleError); setborderRequired(styles.borderRequired); }} ref={title} />
                <div className={titleError}>Δεν έχετε εισάγει τίτλο.</div>
            </div>
            <div style={{ marginTop: '30px' }}>
                <label htmlFor="article_url_key">Κλειδί URL</label>
                <input id="article_url_key" className={`${styles.addTitleInner}`} type='text' ref={urlKey} defaultValue={props.material?.url_key || ''} />
            </div>
            <div style={{ marginTop: '30px' }}>
                <label htmlFor="article_old_url_key">Παλιό κλειδί URL</label>
                <input id="article_old_url_key" className={`${styles.addTitleInner}`} type='text' ref={oldUrlKey} defaultValue={props.material?.old_url_key || ''} />
            </div>
            <div className={styles.tagandimagediv}>
                <div style={{display: "flex", flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "space-around", marginTop: "20px"}}>
                    {props.material.imagePath && <img src={props.material.imagePath} style={{ width: '100px', height: 'auto' }} alt="Thumbnail" />}
                    <input type='file' onChange={e => setMainImage(e.target.files[0])} />
                    {props.material?.id && !props.material.imagePath && <p>Δεν έχετε δηλώσει κεντρική εικόνα</p>}
                    <Popup trigger={<button className={styles.Datebtn}>Ημ/νία Δημοσίευσης</button>} position="right center">
                        <div className={`${styles.addDate}`}>
                            <Calendar defaultZoomLevel="month" showTodayButton={true} defaultValue={date} onValueChange={e => setDate(e)} ></Calendar>
                        </div>
                    </Popup>
                    <div className={styles.tagContainer}>
                        <select value={tag} onChange={e => { setTag(e.target.value); settagError(styles.titleError); setFirstPageFirstNews(e.target.value === '4' ? firstPageFirstNews : false); setFirstPageFirstNews2(e.target.value === '4' ? firstPageFirstNews2 : false); setFirstPageFirstNews3(e.target.value === '4' ? firstPageFirstNews3 : false); }}>
                            <option selected disabled>Επιλέξτε κατηγορία:</option>
                            {
                                tags.map((t) => {
                                    return (<option key={t.id} value={t.id}>{t.description}</option>);
                                })
                            }
                        </select>
                        <div className={tagError}>Επιλέξτε κατηγορία.</div>
                    </div>
                    <button
                            className={`${styles.addArticle}`}
                            onClick={() => {
                                if (fileInputRef.current) {
                                    editorRef.current.instance.focus(); // Focus the editor
                                    fileInputRef.current.click(); // Trigger the file input click
                                }
                            }}
                        >
                            Προσθ. εικ. περιεχομένου
                        </button>
                </div>
            </div>
            <div className={styles.tagandimagediv} style={{justifyContent: 'flex-end'}}>
                    {tag == 4 &&
                        <div style={{display: 'flex'}}>
                            <div>
                                <CheckBox defaultValue={firstPageFirstNews} value={firstPageFirstNews} onValueChange={(v) => { setFirstPageFirstNews(v); if (v) { setFirstPageFirstNews2(false); setFirstPageFirstNews3(false); } }} />&nbsp;&nbsp;F1&nbsp;&nbsp;
                            </div>
                            <div>
                                <CheckBox defaultValue={firstPageFirstNews2} value={firstPageFirstNews2} onValueChange={(v) => { setFirstPageFirstNews2(v); if (v) { setFirstPageFirstNews(false); setFirstPageFirstNews3(false); } }} />&nbsp;&nbsp;F2&nbsp;&nbsp;
                            </div>
                            <div>
                                <CheckBox defaultValue={firstPageFirstNews3} value={firstPageFirstNews3} onValueChange={(v) => { setFirstPageFirstNews3(v); if (v) { setFirstPageFirstNews(false); setFirstPageFirstNews2(false); } }} />&nbsp;&nbsp;F3&nbsp;&nbsp;
                            </div>
                        </div>
                    }
                    {(tag != 4) &&
                        <div style={{display: 'flex', marginLeft: "50px"}}>
                            <div>
                                <CheckBox defaultValue={bottomArticle} value={bottomArticle} onValueChange={(v) => { setBottomArticle(v); if (v) { setBottomArticle2(false); setBottomArticle3(false); setBottomArticle4(false); } }} />&nbsp;&nbsp;B1&nbsp;&nbsp;
                            </div>
                            <div>
                                <CheckBox defaultValue={bottomArticle2} value={bottomArticle2} onValueChange={(v) => { setBottomArticle2(v); if (v) { setBottomArticle(false); setBottomArticle3(false); setBottomArticle4(false); } }} />&nbsp;&nbsp;B2&nbsp;&nbsp;
                            </div>
                            <div>
                                <CheckBox defaultValue={bottomArticle3} value={bottomArticle3} onValueChange={(v) => { setBottomArticle3(v); if (v) { setBottomArticle(false); setBottomArticle2(false); setBottomArticle4(false); } }} />&nbsp;&nbsp;B3&nbsp;&nbsp;
                            </div>
                            <div>
                                <CheckBox defaultValue={bottomArticle4} value={bottomArticle4} onValueChange={(v) => { setBottomArticle4(v); if (v) { setBottomArticle(false); setBottomArticle2(false); setBottomArticle3(false); } }} />&nbsp;&nbsp;B4&nbsp;&nbsp;
                            </div>
                        </div>
                    }
            </div>
            <HtmlEditor
                ref={editorRef}
                className="dx-htmleditor-content"
                style={htmleditorreq}
                value={markup}
                valueType="html"
                onValueChange={handleMarkup}
                onSelectionChange={handleSelectionChange} // Track selection changes
            >
                <Toolbar multiline={true}>
                    <Item name="undo" />
                    <Item name="redo" />
                    <Item name="separator" />
                    <Item name="size" acceptedValues={sizeValues} />
                    <Item name="font" acceptedValues={fontValues} />
                    <Item name="separator" />
                    <Item name="bold" />
                    <Item name="italic" />
                    <Item name="strike" />
                    <Item name="underline" />
                    <Item name="separator" />
                    <Item name="alignLeft" />
                    <Item name="alignCenter" />
                    <Item name="alignRight" />
                    <Item name="alignJustify" />
                    <Item name="separator" />
                    <Item name="orderedList" />
                    <Item name="bulletList" />
                    <Item name="separator" />
                    <Item name="header" acceptedValues={headerValues} />
                    <Item name="separator" />
                    <Item name="color" />
                    <Item name="background" />
                    <Item name="separator" />
                    <Item name="link" />
                    <Item name="clear" />
                </Toolbar>
                <MediaResizing enabled={true} />
            </HtmlEditor>
            <div style={{ marginTop: '30px' }}>
                <label htmlFor="preview">Σύντομη περιγραφή</label>
                <textarea id="preview" ref={preview} defaultValue={props.material?.preview || ''} className={`${styles.addTitleInner}`} rows={10} cols={25} maxLength={1500}></textarea>
            </div>
            <div className={bodyError}>Δεν έχετε εισάγει περιεχόμενο.</div>
            <div><button className={`${styles.addArticle}`} onClick={() => { createUpdateArticle(props.material.id || 0, title, markupRef.current, date, tag, image, firstPageFirstNews, firstPageFirstNews2, firstPageFirstNews3, bottomArticle, bottomArticle2, bottomArticle3, bottomArticle4, preview, urlKey.current.value, oldUrlKey.current.value) }}>{pathname === "/editArticle" ? 'Ενημέρωση άρθρου' : 'Προσθήκη άρθρου'}</button></div>
            <div className="errors">
                {validationErrors.map((err) => <p key={err}>{err}</p>)}
            </div>
            <input
                type="file"
                ref={fileInputRef}
                id="customFileUploader"
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileUpload}
            />
        </div>
    );
}

export default Editor;

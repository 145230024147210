import { useEffect, useState } from "react";
import EmailCampaignsContainer from "../components/pageComponents/Newsletter/EmailCampaignsContainer";
import { Helmet } from "react-helmet-async";

const CampaignList = () => {
    return (
        <div>
            <Helmet>
                <title>{'The Morning View | Campaign list'}</title>
            </Helmet>
            <EmailCampaignsContainer url={"/campaigns/"} />
        </div>
    )
}

export default CampaignList;
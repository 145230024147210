import { useEffect, useState } from "react";
import EmailCampaignsContainer from "../components/pageComponents/Newsletter/EmailCampaignsContainer";
import { Helmet } from "react-helmet-async";
import React from "react";

const Lists = () => {
    return (
        <div>
            <Helmet>
                <title>{'The Morning View | Lists'}</title>
            </Helmet>
            <EmailCampaignsContainer url={"/subscribers/"} />
        </div>
    )
}

export default Lists;
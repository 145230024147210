import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { BaseUrl } from "../../../App";
import axios from "axios";

const EmailCampaignsContainer = ({url}) => {
    const [loading, setLoading] = useState(true);
    const [iframeSource, setIframeSource] = useState("#");
    const onLoadedIframe = () => {
        if (iframeSource != '#' && loading) {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };
    useEffect(() => {
        axios.get(BaseUrl+'/initiate-campaign-session', {
            headers: {
                'Authorization': sessionStorage.getItem('token')
            },
            params: {
                url: url || null
            }
        }).then((response) => {
            if (response?.data?.success) {
                setIframeSource(response.data.data.url);
            }
        });
    }, []);
    return (
        <div>
            {iframeSource != '#' && (<iframe 
            src={iframeSource}
            id="campaignIframe"
            style={{
            visibility: loading ? "none" : "visible",
            border: "none",
            minHeight: '100vh',
            height: "100%",
            width: "100%"}} 
            height="100%" 
            width="100%"
            onLoad={() => onLoadedIframe}>
            </iframe>)}
        </div>
    );
}
export default EmailCampaignsContainer;
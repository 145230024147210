import { useEffect, useState } from "react";
import EmailCampaignsContainer from "../components/pageComponents/Newsletter/EmailCampaignsContainer";
import { Helmet } from "react-helmet-async";
import React from "react";

const Billing = () => {
    return (
        <div>
            <Helmet>
                <title>{'The Morning View | Billing'}</title>
            </Helmet>
            <EmailCampaignsContainer url={"/billing/"} />
        </div>
    )
}

export default Billing;
import React from "react";
import styles from "../../styles/SideBarMenu.module.css";
import { Link } from "react-router-dom";

const SideBarCampaignMenuList = (props) => {
    return(
        <div>
            <div className={styles.SideNavDivFilterCompsInner} key={'campaign-list'}>
                <Link to={"/campaigns/campaign-list"} key={"campaign-list"}>
                    Campaign list
                </Link>
            </div>
            <div className={styles.SideNavDivFilterCompsInner} key={'lists'}>
                <Link to={"/campaigns/lists"} key={"lists"}>
                    Lists
                </Link>
            </div>
            <div className={styles.SideNavDivFilterCompsInner} key={'billing'}>
                <Link to={"/campaigns/billing"} key={"billing"}>
                    Billing
                </Link>
            </div>
        </div>
    )

}
export default SideBarCampaignMenuList;
import { Link } from "react-router-dom";
import ArticlesSingle from "./ArticlesSingle";

const ArticlesContainer = (props) => {
    if (!props.allarticles) {
        return (
            <div>LOADING..</div>
        )
    }
    return (
        <div>
            {props.allarticles.map((article)=>{
                return (
                    <div key={article.id}>
                        <ArticlesSingle imagePath={article.imagePath} title={article.title} body={article.body} date={article.releaseDate} articleId = {article.id}
                        tagId={article.tag} author={article.author.description}/>
                    </div>
                )
            }) }
        </div>
    );
}
export default ArticlesContainer;
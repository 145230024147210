import { useEffect, useState } from "react";
import { BaseUrl, tokenVal } from '../App';
import axios from "axios";
import ArticlesContainer from "../components/pageComponents/Articles/ArticlesContainer";
import { useLocation } from "react-router";
import EditArticleContainer from "../components/pageComponents/EditArticle/EditArticleContainer";

const EditArticle = () => {
    const Search = useLocation();
    const [isLoading, setisLoading] = useState(true);
    const [isData, setisData] = useState([]);
    useEffect(()=>{
        // tokenVal();
        axios.get(BaseUrl+"/get-single-article",{
            params:{
                id:Search.state.id
            }
        }).then(
            (response)=>{
                setisData(response.data.data);
                setisLoading(false);        
            }
        )
    },[])
    
    return(
        isLoading == false ? 
        <div>
            
            <EditArticleContainer material={isData}/>
        </div>:
        <div>
            LOADING...
        </div>
    )
}
export default EditArticle;
import SingleTrash from "./SingleTrash";






const TrashList = (props) => {
    if(props.allTrashes == []){
        return (<div>Ο Κάδος είναι άδειος</div>);
    }else{
        return (
            <div>
                {props.allTrashes.map((article)=>{
                    return (
                        <div key={article.id}>
                            <SingleTrash imagePath={article.imagePath} title={article.title} body={article.body} date={article.releaseDate} articleId = {article.id}
                            tagId={article.tag} author={article.author.description}/>
                        </div>
                    )
                }) }
            </div>
        );
    }
}

export default TrashList;
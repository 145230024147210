import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { BaseUrl } from "../../../App";
import styles from "../../../styles/Login.module.css";
import logo from "../../../images/morningviewLogo.jpg"
import { Helmet } from "react-helmet-async";

const LoginContainer = () => {
    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [wrongCreds,setWrongCreds] = useState({display: 'none'})
    const login = (username,password) => {
        axios.get(BaseUrl+'/login',{
            params: {
                username: username,
                password: password
            }
        }).then((response) => {
            if(response.data.success == true){
                const token = response.data.data.tokenType+' '+response.data.data.token.plainTextToken;
                sessionStorage.setItem('token',token);
                window.location.replace('/');
            }
        }).catch(err => {
            setWrongCreds({display: 'block' ,color: 'red'})
        })
}
    const enterPress = (e,username,password) =>{
        if(e.key == 'Enter'){
            login(username,password);
        }
        
    }
    return ( 
        
        <div className={styles.formContain}>
            
            <div className={styles.PhotoContain}>
                <div className={styles.PhotoContainInner}>
                    <img src={logo} alt="" width='100%' style={{'borderRadius': '5px'}}/>  
                    <input className={styles.credentialsStyle} type='text' placeholder='Όνομα χρήστη...' onChange={e => {setUsername(e.target.value);setWrongCreds({display: 'none'})}} onKeyDown={e => {enterPress(e,username,password)}}/>
                    <input className={styles.credentialsStyle} type='password' placeholder='Κωδικός...' onChange={e => {setPassword(e.target.value);setWrongCreds({display: 'none'})} } onKeyDown={e => {enterPress(e,username,password)}} />
                    <button className={styles.submitStyle} onClick={()=>{login(username,password)}}>Σύνδεση</button>
                    <div style={wrongCreds}>Λάθος όνομα χρήστη ή Κωδικός</div>
                </div>  
            </div>
            <div className={styles.InputContain}></div>
        </div>
    );
}

export default LoginContainer;
import { Link } from "react-router-dom";
import morningviewLogo from "../../images/mvlogonew.png";
import styles from "../../styles/TopBar.module.css";
import React from "react";
import MyDate from "../generalComponents/date";
import { useState } from "react";
import axios from "axios";
import { BaseUrl } from "../../App";
const logout = () => {
    var token = sessionStorage.getItem('token');
    axios.post(BaseUrl+'/logout',{},{
        headers: {
            Authorization: token
        }
    }).then(response => {
        if(response.data.success === true){
            sessionStorage.removeItem('token');
            window.location.replace('/');
        }
    });
}
const TopBar = () => {
    window.onscroll = function() {myFunction()};

    const [header,setHeader] = useState(false);
    function myFunction() {
        if (window.pageYOffset > 80) {
            setHeader(true)
        } else {
            setHeader(false)
        }
    }

    return (
        header === false ?
        <div className={styles.TopBarContainer}>
            <div className={styles.TopBarContainerInner}>
                <div><Link to ="/"><img className={styles.logo} src={morningviewLogo} alt="Morning View Logo" /></Link></div>
                <div className={styles.WeclomeContainer}>
                    <div><span className={styles.datetimeText}><MyDate /></span></div>
                    <div><span className={styles.welcomeText}>Καλώς ήρθατε, στο διαχειριστικό του Morning View</span></div>
                </div>  
                <div><Link to ="#" onClick={()=>{logout()}}><span className={styles.logoutText}>Αποσύνδεση</span></Link></div>
            </div>
        </div> : 
       <div className={styles.TopBarContainerStick}>
        <div className={styles.TopBarContainerInner}>
            <div><Link to ="/"><img className={styles.logo} src={morningviewLogo} alt="Morning View Logo" /></Link></div>
            <div className={styles.WeclomeContainer}>
                <div><span style={{position: 'relative', top: '-25px', fontSize: '14px'}} className={styles.datetimeText}><MyDate /></span></div>
            </div>  
            <div><Link to ="/" onClick={()=>{logout()}}><span style={{position: 'relative', top: '-25px'}} className={styles.logoutText}>Αποσύνδεση</span></Link></div>
        </div>
       </div>
    )
}
export default TopBar;
import { Link } from "react-router-dom";
import styles from "../../styles/FooterCopyright.module.css";

const FooterCopyright = () => {

    return (
        <div className={styles.footerContainer}>
           <a href="https://citrine.gr/" target="_blank">{new Date().getFullYear()} © By Citrine</a>
        </div>
    )
}

export default FooterCopyright;
import { useEffect, useState } from "react";
import { BaseUrl, tokenVal } from '../App';
import axios from "axios";
import ArticlesContainer from "../components/pageComponents/Articles/ArticlesContainer";
import { useLocation } from "react-router";
import { Helmet } from "react-helmet-async";

const Articles = () => {
    const search = useLocation();
    const tag = search.state;
    var theTagId = tag == null ? null : tag.tagId
    const [isLoading,setLoading] = useState(true);
    const [article,setArticle] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(false);
    
    useEffect(() => {
        axios.get(BaseUrl + '/get-articles-admin', {
            params: {
                tagId: theTagId,
                page: page
            }
        }).then((response) => {
            setArticle(response.data?.data?.data);
            if (response.data?.data?.next_page_url) {
                setHasMorePages(true);
            } else {
                setHasMorePages(false);
            }
            setLoading(false);
        });
    }, [theTagId, page])

    return (
        <div onChange={()=>{window.reload()}}>
            <Helmet>
                <title>{'The Morning View | Αρθρογραφία - Διαχειριστικό'}</title>
            </Helmet>
            <ArticlesContainer allarticles = {article} />
            <div style={{display: 'flex', flexDirection: "row", justifyContent: 'end'}}>
                <a href="#" onClick={(e) => {
                    e.preventDefault();
                    if (page === 1) {
                        return;
                    }
                    setPage(page - 1)
                }}>&larr;&nbsp; Προηγούμενη σελίδα</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                <a href="#" onClick={(e) => {
                    e.preventDefault();
                    if (!hasMorePages) {
                        return;
                    }
                    setPage(page + 1)
                }}>Επόμενη σελίδα &nbsp;&rarr;</a>
            </div>
        </div>
    )
}

export default Articles;
import React from "react";
import SideBarFilter from "./SideBarFilter";
import styles from "../../styles/SideBarMenu.module.css";
import { Link } from "react-router-dom";

const SideBarFilterList = (props) => {
    return(
        <div>
            <div className={styles.SideNavDivFilterCompsInner}>
                <Link to={"/articles"}>Ολα τα άρθρα</Link>
            </div>
            {props.tags.data.map((tag)=>{
                return(
                    <div className={styles.SideNavDivFilterCompsInner} key={'tag_'+tag.id}>
                    <Link to={"/articles"} state={{tagId:tag.id}} key={"tag"+tag.id}>
                        <SideBarFilter  id={tag.id} desc={tag.description}/>
                    </Link>
                    </div>

                );
            })}
        </div>

    )

}
export default SideBarFilterList;
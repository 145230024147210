import React from "react";

export default function MyDate() {
  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;

  return (
    <div>
      <span>Ημερομηνία: {date}</span>
    </div>
  );
}
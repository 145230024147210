import clock from '../../../images/datedark.png';
import trash from '../../../images/trash_red.png';
import edit from '../../../images/edit_red.png';
import { Link } from 'react-router-dom';
import styles from '../../../styles/Articles.module.css'
import axios from 'axios';
import { BaseUrl } from '../../../App';
import { useState } from 'react';
const deleteArticle = (id) => {
    axios.post(BaseUrl+'/delete-article',{
        id: id
    }).then((response) => {
        if (response.data.success == true){
            window.location.reload();
        }
    });
}

const ArticlesSingle = (props) => {
    const [confirm,setconfirm] = useState(styles.confirmContainerHidden);
    return (
        
            <div className={`${styles.articlesContainerInner}`}>
                    <div className={styles.articlesDivImg}>
                        <div className={styles.articlesMainImg} style={{backgroundImage: "url('"+props.imagePath+"')"}}></div>
                    </div>
                    <div className={styles.articlesDetailsDiv}>
                    
                        <div className={styles.articlesTitle}>
                            {props.title}
                        </div>
                        <div className={styles.articlesBody} dangerouslySetInnerHTML={{__html: props.body.substring(0,200)}}>
                        </div>
                        <div className={styles.articlesDate}>
                            <img src={clock}></img>
                            <div>{props.date}</div>
                        </div>
                        <div className={styles.bottomContainer}>
                            <div className={styles.articlesAuthor}>
                                {props.author}
                            </div>
                            <div className={styles.editdelete}>
                                <Link to={'/editArticle'} state={{id: props.articleId}}>
                                    <div className={styles.editbtn}><img src={edit}></img>Επεξεργασία</div>
                                </Link>
                                <div  className={styles.deletebtn} onClick={() =>{setconfirm(styles.confirmContainer)}}><img src={trash}></img>Διαγραφή</div>
                            </div>
                        </div>
                        <div className={confirm} >
                                <div  className={styles.confirmBtn} onClick={() =>{deleteArticle(props.articleId)}}>Ναι</div>
                                <div  className={styles.confirmBtn} onClick={() =>{setconfirm(styles.confirmContainerHidden)}}>'Οχι</div>
                        </div>
                    </div>
            </div>
            
        
    );
}
export default ArticlesSingle;
import React from "react";

const SideBarFilter = (props) => {
    
    return(
        <div> 
            {props.desc}
        </div>
    )
}
export default SideBarFilter;
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BaseUrl } from "../../App";
import styles from "../../styles/SideBarMenu.module.css";
import SideBarFilterList from "./SideBarFilterList";
import SideBarCampaignMenuList from './SideBarCampaignMenuList';

const SideBarMenu = () => {
    const [isLoading,setLoading] = useState(true);
    const [data,setdata] = useState([]);
    const [ShowFilters, setShowFilters] = useState(false);
    const [showCampaigns, setShowCampaigns] = useState(false);

    useEffect(()=>{
        axios.get(BaseUrl+'/get-tags').then((response)=>{
            setdata(response.data);
            setLoading(false);
            // console.log(data);
        })
    }, [])
    if(isLoading == false){
        return(
            <div className={styles.SideBarContainer}>
                <div className={styles.SideBarContainerInner}>  
                    <div className={styles.SideNavDiv}><Link to ="/newArticle"> Προσθήκη Άρθρου</Link></div>
                    <div className={styles.SideNavDiv}><Link to ="/articles"> Δείτε την Αρθρογραφία</Link></div>
                    <div className={styles.SideNavDiv}><Link to ="/trashBin"> Διεγραμμένα </Link></div>
                    <div className={styles.SideNavDiv}><Link to ="/comments"> Σχόλια</Link></div>
                    <div onMouseEnter={() => setShowCampaigns(true)} onMouseLeave={() => setShowCampaigns(false)}>
                        <div className={styles.SideNavDiv}>
                            <Link to ="#">Campaigns</Link>
                        </div>
                        <div className={showCampaigns == true ? styles.SideNavDivFilterComps : styles.SideNavDivFilterCompsNone} >
                            <SideBarCampaignMenuList />
                        </div>
                    </div>
                    <div onMouseEnter={() => setShowFilters(true)} onMouseLeave={() => setShowFilters(false)}>
                        <div className={`${styles.SideNavDiv} ${styles.Last}`} >
                            <Link to ="#"> Φίλτρα </Link>
                        </div>
                        
                        <div className={ShowFilters==true ? styles.SideNavDivFilterComps : styles.SideNavDivFilterCompsNone} >
                            <SideBarFilterList tags={data} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        <div>loading</div>
    }
}

export default SideBarMenu;
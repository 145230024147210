import { Link } from "react-router-dom";
import { BaseUrl } from "../App";
import axios from "axios";

const ArticlesContainer = (props) => {
    if (!props.comments) {
        return;
    }
    const deleteComment = (id) => {
        const token = sessionStorage.getItem('token');
        axios.post(BaseUrl + '/delete-article-comment-admin', {   
            id: id 
        }).then((response) => {
            if (response.data.success) {
                alert('Το σχόλιο διεγράφη επιτυχώς');
            }
        });
    };
    const approveComment = (id) => {
        const token = sessionStorage.getItem('token');
        axios.post(BaseUrl + '/approve-article-comment-admin', {   
            id: id 
        }).then((response) => {
            if (response.data.success) {
                alert('Το σχόλιο εγκρίθηκε επιτυχώς');
            }
        });
    };
    return (
        <div>
            {
                props.comments?.map((comment) => {
                    let username = null;
                    if (comment.comment_email && comment.comment_email != '') {
                        username = comment.comment_email;
                    } else if (comment.comment_name && comment.comment_name != '') {
                        username = comment.comment_name;
                    } else if (comment.username && comment.username != '') {
                        username = comment.username;
                    }
                    return (
                        <div style={{marginBottom: "30px", borderBottom: "1px solid #eee", paddingBottom: "10px"}}>
                            <a href={'https://www.morningview.gr/article/' + comment.article_url_key} target="_blank">{comment.article_title}</a>
                            <p style={{padding: "0px", margin: "0px", fontSize: "14px"}}>Από: {username}, στις: {comment.created_at} &mdash; {comment.approved ? '(Εγκρίθηκε)' : '(Δεν έχει εγκριθεί)'} &mdash; <a style={{cursor: "pointer"}} href="#" onClick={(e) => {e.preventDefault(); deleteComment(comment.id);}}>Διαγραφή</a> &mdash; <a style={{cursor: "pointer"}} href="#" onClick={(e) => {e.preventDefault(); approveComment(comment.id); }}>Έγκριση</a></p>
                            <p style={{paddingLeft: "10px", paddingTop: "10px"}}>{comment.text}</p>
                        </div>
                    )
                }) 
            }
        </div>
    );
}
export default ArticlesContainer;
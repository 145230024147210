import { useEffect, useState } from "react";
import { BaseUrl, tokenVal } from '../App';
import axios from "axios";
import CommentsContainer from "./CommentsContainer";
import { useLocation } from "react-router";
import { Helmet } from "react-helmet-async";

const Articles = () => {
    const search = useLocation();
    const [isLoading,setLoading] = useState(true);
    const [comments,setComments] = useState([]);
    
    useEffect(()=>{
        axios.get(BaseUrl+'/get-article-comments-admin',{
        }).then((response) => {
            setComments(response.data.data);
            setLoading(false);            
        });
    }, [])

    return (
        <div onChange={()=>{window.reload()}}>
            <Helmet>
                <title>{'The Morning View | Σχόλια - Διαχειριστικό'}</title>
            </Helmet>
            <CommentsContainer comments={comments} />
        </div>
    )
}

export default Articles;